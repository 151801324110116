import React from 'react';
// import nostr from './images/nostr.png';
import xIcon from './images/x-twitter.jsx';

export const projects = [
  {
    title: 'The Wet Shaving Store',
    description: 'An e-commerce store for wet shaving products and accessories.',
    url: 'https://wetshaving.store',
  },
  {
    title: 'COVID-19 Informer',
    description: 'A dashboard with stats for worldwide COVID-19 cases.',
    url: 'https://covid-19informer.netlify.app',
    repo: 'https://github.com/aamay001/covid-19-informer-client',
  },
  {
    title: 'imNext',
    description: 'imNext is a schedule and customer management SaaS designed for low tech solopreneurs.',
    url: 'https://imnext.online',
  },
  {
    title: 'Gobi\'s Amazing Adventure',
    description: 'A game developed while working at EMWD. The characters are based on a book created to teach kids about the water reclamation process; made with React.',
    url: 'https://gobis-adventure.emwd.org/'
  },
  {
    title: 'resumeJS',
    description: 'resumeJS is an opinionated, open source, resume builder designed for developers.',
    url: 'https://resumejs.netlify.app/',
    repo: 'https://github.com/aamay001/react-resume',
  },
  // {
  //   title: 'TellMeOnDate',
  //   description: 'A sample SPA that schedules and sends reminders via sms.',
  //   url: 'https://tellmeondate.herokuapp.com/',
  //   repo: 'https://github.com/aamay001/reminder-app',
  // },
  {
    title: 'City Dashboard',
    description: 'A sample SPA showcasing several API’s; provides useful information about any city.',
    url: 'https://aamay001.github.io/city-dashboard/',
    repo: 'https://github.com/aamay001/city-dashboard',
  },
  {
    title: 'EasyDeploy',
    description: 'A Windows forms client for PsExec. Includes several custom PC management tools.',
    url: 'http://easydeploy.andyamaya.com/',
    repo: 'https://github.com/aamay001/easy-deploy',
  },
  {
    title: 'Spoids',
    description: 'A tower defense game for Windows and Xbox 360 Indie Arcade.',
    url: 'https://store.steampowered.com/app/589640/Spoids/',
  },
];

/*const copyNostrId = (event) => {
  event.preventDefault();
  event.stopPropagation();
  navigator.clipboard.writeText('npub1c2smpt9n4r7k6u43a9kaju49m8dethrj2glxc3zn6xnfgauq7lgsnahdnj');
  alert('npub id copied to clipboard!');
};*/

export const contactLinks = [
  {
    name: 'GitHub',
    url: 'https://github.com/aamay001',
    icon: () => <i className="fa fa-github" style={{ fontSize:48 }}></i>
  },
  {
    name: 'Instagram',
    url:  'https://www.instagram.com/aamay001/',
    icon: () => <i className="fa fa-instagram" style={{ fontSize:48 }}></i>
  },
  {
    name: 'X',
    url:  'https://x.com/aamay001',
    icon: xIcon,
  },
  {
    name: 'LinkedIn',
    url:  'https://www.linkedin.com/in/andyamaya/',
    icon: () => <i className="fa fa-linkedin" style={{ fontSize:48 }}></i>
  },
];
